<template>
<div>
  <table>
    <tr>
        <td><div v-if="score.scoreImageList[0]"><router-link :to="{path:'/piano/'+score.id}"><img :src="score.scoreImageList[0].file.url"/></router-link></div></td>
        <td>
            <div><span>乐曲名称：</span><input v-model="score.name"/></div>
            <div><span>作者/出处：</span><input v-model="score.author"/></div>
            <div><span>乐谱类型：</span>
              <select v-model="score.typeId">
                  <option v-for="t,i of scoreTypeList" :key="i" :value="t.id">{{t.type}}</option>
              </select>
          </div>
        </td>
        <td>
          <div><span>是否公开：</span>
            <select v-model="score.power">
              <option v-for="(val,key) in ScorePower" :value="val.value" :key="key">{{ val.tag }}</option>
            </select>
          </div>
          <div><span>状态：</span>{{ScoreStatus[score.status].tag}}</div>
          <div><span>页数：</span>{{score.scoreImageList.length}}</div>
        </td>
        <td>
            <div><PButton size="mini" :active="sActive" :hoverActive='false' @click="saveScore">{{sActive==true?'保存修改':'保存中...'}}</PButton></div>
            <div><PButton size="mini" :active="dActive" :hoverActive='false' @click="deleteScore">{{dActive==true?'删除':'删除中...'}}</PButton></div>
        </td>
    </tr>
  </table>
</div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { ScorePower,ScoreStatus } from '../../../../../enum';
//import {getScoreType} from '../../../../../api/score'
// import {ref} from 'vue'
// import Message from '../../../../../components/library/Message'
// import axios from '../../../../../utils/request';
export default {
    name:'ScoreOpe',
    props:{
      modelValue:{
        type:Object,
        default:()=>{}
      },
      sActive:{
        type:Boolean,
        default:true
      },
      dActive:{
        type:Boolean,
        default:true
      },
      scoreTypeList:{
        type:Array,
        default:()=>[]
      }
    },
    setup(props,{emit}){
      const score=useVModel(props,'modelValue',emit);
      const deleteScore=()=>{
        emit('deleteScore');
      }
      const saveScore=()=>{
        emit('saveScore');
      }
      //let type=ref(null);
      
      // getScoreType().then((res)=>{
      //   console.log(res);
      //   type.value=res.data;
      // }).catch((err)=>{
      //   console.log(err);
      // })
      return {score,deleteScore,saveScore,ScorePower,ScoreStatus};
    }
}
</script>

<style lang="less" scoped>
table{
  tr{
    padding: 100px;
    //height: 200px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    td{
      padding-right: 20px;
      img{
        width:80px;
        height: 100px;
        margin: 10px;
      }
      div{
        margin-bottom: 10px;
        span{
          font-size: 800;
        }
      }
    }
  }
}
</style>