// 1. 创建一个新的axios实例
// 2. 请求拦截器，如果有token进行头部携带
// 3. 响应拦截器：1. 剥离无效数据  2. 处理token失效
// 4. 导出一个函数，调用当前的axsio实例发请求，返回值promise

import axios from 'axios'
import router from '@/router'
// 导出基准地址，原因：其他地方不是通过axios发请求的地方用上基准地址
export const baseURL = process.env.VUE_APP_Server
const instance = axios.create({
  // axios 的一些配置，baseURL  timeout
  baseURL,
  // timeout: 5000
})

instance.interceptors.request.use(config => {
  //console.log("baseURL:"+baseURL);
  //console.log(process.env.NODE_ENV);
  // // 拦截业务逻辑
  // // 进行请求配置的修改
  // // 如果本地有token就在头部携带
  // // 1. 获取用户信息对象
  // const { profile } = store.state.user
  // // 2. 判断是否有token
  let user=JSON.parse(localStorage.getItem("user"));
  if (user&&user.token) {
    // 3. 设置token
    config.headers.Jerry = user.token;
  }
  return config
}, err => {
  return Promise.reject(err)
})

// res => res.data  取出data数据，将来调用接口的时候直接拿到的就是后台的数据
instance.interceptors.response.use(res => {
  if(res.data.code==200){
    return res.data.data;
  }else if(res.data.code==600){
    // 当前路由地址
    // 组件里头：`/user?a=10` $route.path === /user  $route.fullPath === /user?a=10
    // js模块中：router.currentRoute.value.fullPath 就是当前路由地址，router.currentRoute 是ref响应式数据
    localStorage.setItem("user",null);
    console.log("setItem");
    const fullPath = encodeURIComponent(router.currentRoute.value.fullPath);
    // encodeURIComponent 转换uri编码，防止解析地址出问题
    router.push('/login?redirectUrl=' + fullPath);
    return res;
  }else{
    return Promise.reject(res.data);
  }
  
}, err => {
  // // 401 状态码，进入该函数
  // if (err.response && err.response.status === 401) {
  //   // 1. 清空无效用户信息
  //   // 2. 跳转到登录页
  //   // 3. 跳转需要传参（当前路由地址）给登录页码
  //   store.commit('user/setUser', {})
  //   // 当前路由地址
  //   // 组件里头：`/user?a=10` $route.path === /user  $route.fullPath === /user?a=10
  //   // js模块中：router.currentRoute.value.fullPath 就是当前路由地址，router.currentRoute 是ref响应式数据
  //   const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
  //   // encodeURIComponent 转换uri编码，防止解析地址出问题
  //   router.push('/login?redirectUrl=' + fullPath)
  // }
  return Promise.reject(err)
})

// instance.post=function(url,data){
//   let request={
//     url:url,
//     data:data,
//     method:"post"
//   }
//   return instance(request);
// }

// instance.get=function(url,params){
//   console.log(params);
//   let request={
//     url:url,
//     params:params,
//     method:"get"
//   }
//   return instance(request);
// }

// 请求工具函数
export default instance;