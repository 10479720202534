<template>
    <div class='myUpload'>
      <div v-for="score,i of scores" :key="i">
        <ScoreOpe class="item" :scoreTypeList="scoreTypeList" :modelValue="score" @deleteScore="delScore(i)" @saveScore="savScore(i)" :dActive="btnActive[i].dActive" :sActive="btnActive[i].sActive"/>
      </div>
    </div>
</template>

<script>
import ScoreOpe from './components/scoreOpe.vue'
//import {queryScoreByUserId,deleteScore,updateScore} from '../../../../api/score'
//import {useStore} from 'vuex'
import { ref} from 'vue'
import Message from '../../../../components/library/Message'
import axios from "../../../../utils/request"
import qs from "qs";
export default {
  name: 'myUpload',
  components:{
    ScoreOpe
  },
  setup(){
    const btnActive=ref([]);
    //const store=useStore();
    const scores=ref([]);
    let trim=(str)=>
    { 
      return str.replace(/(^\s*)|(\s*$)/g, ""); 
    }

    let check=(s)=>{
      s.name=trim(s.name);
      s.author=trim(s.author);
      if(s.name==''){
        Message({type:'warn',text:'请输入乐曲名称'});
        return false;
      }else if(s.author==''){
        Message({type:'warn',text:'请输入作者/出处'});
        return false;
      }else{
        return true;
      }
    }
    const delScore=(i)=>{
      if(btnActive.value[i].dActive==false){
        return;
      }
      if(!check(scores.value[i])){
        return;
      }
      btnActive.value[i].dActive=false;
      axios.post("/score/deleteScoreById",qs.stringify({id:scores.value[i].id})).then(()=>{
        Message({type:'success',text:'删除成功'});
      }).catch((err)=>{
        Message({type:'error',text:err.message});
      }).finally(()=>{
        btnActive.value[i].dActive=true;
      })
      // deleteScore(scores.value[i].id).then((res)=>{
      //   if(res.data=='success'){
      //     scores.value.splice(i,1);
      //     Message({type:'success',text:'删除成功'});
      //     btnActive.value[i].dActive=true;
      //   }else{
      //     Message({type:'error',text:'删除失败'});
      //     btnActive.value[i].dActive=true;
      //   }
      // }).catch(()=>{
      //   Message({type:'error',text:'删除失败'});
      //   btnActive.value[i].dActive=true;
      // })
      //console.log(i);
    }
    const savScore=(i)=>{
      if(btnActive.value[i].sActive==false){
        return;
      }
      btnActive.value[i].sActive=false;
      axios.post("/score/updateScore",scores.value[i]).then(()=>{
        Message({type:"success",text:"保存成功"});
      }).catch((err)=>{
        Message({type:"error",text:err.message});
      }).finally(()=>{
        btnActive.value[i].sActive=true;
      })
      // updateScore(scores.value[i]).then((res)=>{
      //   if(res.data=='success'){
      //     Message({type:'success',text:'保存成功'});
      //     btnActive.value[i].sActive=true;
      //   }
      // }).catch(()=>{
      //   Message({type:'error',text:'保存失败'});
      //   btnActive.value[i].sActive=true;
      // })
    }

    //let user=JSON.parse(localStorage.getItem("user"));
    axios.get("/score/getUserScoreList").then((res)=>{
      scores.value=res;
      for(let i=0;i<scores.value.length;i++){
        let act={dActive:true,sActive:true};
        btnActive.value.push(act);
      }
    }).catch((err)=>{
      Message({type:"error",text:err.message});
    })
    // watch(()=>{return store.state.user.loginUser},(newVal)=>{
    //   if(newVal){
    //     console.log(newVal.id);
    //     queryScoreByUserId(newVal.id).then((res)=>{
    //       console.log(res);
    //       scores.value=res.data;
    //       for(let i=0;i<scores.value.length;i++){
    //         let act={dActive:true,sActive:true};
    //         btnActive.value.push(act);
    //       }
    //     })
    //   }
    // },{immediate:true})

    let scoreTypeList=ref([]);
    axios.get("/scoreType/getScoreTypeList").then((data)=>{
      scoreTypeList.value=data;
    }).catch((err)=>{
      Message({type:"error",text:err.message});
    })
    return {delScore,scores,scoreTypeList,btnActive,savScore};
  }
  
}
</script>

<style scoped lang='less'>
.item{
  margin-bottom: 20px;
}
</style>